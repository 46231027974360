<template>
  <div>
    <div class="container">
      <!-- 选择-->
      <a-tabs default-active-key="login" @change="radioChange" class="tab">
        <a-tab-pane key="login" tab="登陆日志"/>
        <a-tab-pane key="action" tab="业务日志"/>
      </a-tabs>
      <component :is="currentTabComponent"></component>
    </div>
  </div>
</template>

<script>
// import Action from '@/views/system/journal/action/index'
import Action from '@/views/system/journal/acoperlog/acOperLogIndex'
// import Login from '@/views/system/journal/login/index'
import Login from '@/views/system/journal/acloginlog/acLoginLogIndex'

export default {
  name: 'Index',
  data () {
    return {
      currentTabComponent: Login
    }
  },
  methods: {
    /**
     * readio发生变化是触发
     * @param label
     */
    radioChange (label) {
      switch (label) {
        case 'login': this.currentTabComponent = Login; break
        case 'action': this.currentTabComponent = Action; break
      }
    }
  }
}
</script>

<style lang="less" scoped>

.container{
  background: #fff;
  padding: 20px;
}
.tab{
  padding: 0 20px;
}
/deep/ .ant-card-body{
  padding: 8px 20px;
}
</style>
